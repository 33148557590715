import images from "../components/ImageManager";
import React from "react";

function About() {
  return (
    <section id="about" className="container about-background">
      <div className="row">
        <div className="about">
          {/* LEFT-SIDE */}
          <div className="about__content">
            <div className="content__text">
              <div className="highlighted-section-name">
                <h2>📑</h2>
                <div>&nbsp;SCHEDULE PERFORMANCE</div>
              </div>
              <h2 className="content-title">
                통계를 통해 결과를 직관적으로
              </h2>
              <div className="content-paragraph">
                정교한 데이터 분석과 시각화를 통해 일정을 심층적으로 파악하고<br/>
                직관적인 UI를 통해 전달하여 사용자는 빠른 피드백을 할 수 있습니다.
              </div>
            </div>
          </div>
          {/* RIGHT-SIDE */}
          <div className="about-cards">
            <img src={images.aboutIcon} style={{width: "57rem"}} alt="high-quality code"/>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
