import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronUp} from "@fortawesome/free-solid-svg-icons";

function moveToTop() {
  return (
    <button className="btn-to-top"><FontAwesomeIcon icon={faChevronUp}/></button>
  );
}

export default moveToTop;
