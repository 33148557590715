import React, {useState} from 'react';
import "../../css/home/notice/notice-register.css"
import Form from 'react-bootstrap/Form';
import {Button} from "react-bootstrap";

function NoticeRegister() {
  const [noticeContent, setNoticeContent] = useState<string>(
    "여러분 안녕하세요! \n" +
    "Dailyge 팀 입니다.\n\n" +
    "이번 공지사항에선 다음과 같은 내용들을 공유하려 합니다.\n\n"
  );

  const handleContentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNoticeContent(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const title = formData.get('title') as string;
    // 여기서 공지사항 등록 처리를 할 수 있습니다.
    console.log("제목:", title);
    console.log("내용:", noticeContent);
    // 등록 후 필요한 처리 추가
  };

  return (
    <div className="notice-register">
      <div className="header">
        <h1>공지사항 등록</h1>
      </div>
      <div className="body">
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>공지사항 제목</Form.Label>
            <Form.Control type="text" placeholder="제목을 입력하세요"/>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>공지사항 내용</Form.Label>
            <Form.Control
              as="textarea"
              rows={6}
              value={noticeContent}
              onChange={handleContentChange}
            />
          </Form.Group>
        </Form>
        <div className="buttons">
          <Button variant="primary" size="lg">등록</Button>
        </div>
      </div>
    </div>
  );
}

export default NoticeRegister;
