import React, {useState} from 'react';
import {Link, Outlet, useLocation} from "react-router-dom";
import Header from "../common/Header";

function Docs() {
  const location = useLocation();
  const [selected, setSelected] = useState<number | null>(null);

  const handleClick = (index: number) => {
    setSelected(index);
  };

  const sidebarItems = [
    {href: "/docs/guide", label: "API 가이드"},
  ];

  return (
    <>
      <Header className="docs-"></Header>
      <div className="docs-container">
        <aside className="docs-sidebar">
          {/* 사이드바 내용 */}
          <ul>
            {sidebarItems.map((item, index) => (
              <li key={index} className={location.pathname === item.href ? 'selected' : ''}>
                <Link to={item.href} onClick={() => handleClick(index)}>{item.label}</Link>
              </li>
            ))}
          </ul>
        </aside>
        <Outlet/>
      </div>
    </>
  );
};

export default Docs;