import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import axios from 'axios';
import LoadingPage from "../common/LoadingPage";

interface AuthResponse {
  data: {
    accessToken: string;
  };
}

function AuthCallback() {
  const location = useLocation();

  useEffect(() => {
    const login = async () => {
      const searchParams = new URLSearchParams(location.search);
      const code = searchParams.get('code'); // URL에서 인증 코드 추출
      if (code) {
        await axios.get<AuthResponse>(process.env.REACT_APP_API_SERVER_URL + `/api/oauth2?code=${code}`, {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }).catch(error => {
          console.error('인증 처리 중 오류 발생:', error);
          alert('인증에 실패했습니다. 다시 시도해 주세요.');
        })
      } else {
        alert('인증 코드를 찾을 수 없습니다. 다시 로그인해 주세요.');
      }
      window.location.href = "/"
    }
    login();
  }, [location]);

  return <LoadingPage/>;
}

export default AuthCallback;
