import React from 'react';
import '../../css/home/docs/docs.css';
import ErrorContainer from "./ErrorContainer";

interface ErrorCode {
  statusCode: number;
  errorCode: string;
  message: string;
}

const errorCodes: ErrorCode[] = [];

function Docs() {
  const commonError: ErrorCode[] = [
    {
      "statusCode": 400,
      "errorCode": "BAD_REQUEST",
      "message": "올바른 파라미터를 입력해주세요"
    },
    {
      "statusCode": 400,
      "errorCode": "INVALID_PARAMETERS",
      "message": "올바른 파라미터를 입력해주세요"
    },
    {
      "statusCode": 403,
      "errorCode": "INVALID_USER_ID",
      "message": "올바른 사용자 아이디가 아닙니다."
    },
    {
      "statusCode": 403,
      "errorCode": "INVALID_USER_TOKEN",
      "message": "유효하지 않는 사용자 토큰입니다."
    },
    {
      "statusCode": 403,
      "errorCode": "UN_AUTHORIZED",
      "message": "권한이 존재하지 않습니다."
    },
    {
      "statusCode": 404,
      "errorCode": "INVALID_URL",
      "message": "올바르지 않은 URL 입니다."
    },
    {
      "statusCode": 500,
      "errorCode": "INTERNAL_SERVER_ERROR",
      "message": "서버 내부 오류입니다."
    },
    {
      "statusCode": 500,
      "errorCode": "DATA_ACCESS_EXCEPTION",
      "message": "데이터베이스와 통신하는 과정에서 오류가 발생했습니다."
    },
    {
      "statusCode": 500,
      "errorCode": "COMMON_UN_RESOLVED_EXCEPTION",
      "message": "정의되지 않은 공통 오류입니다."
    },
    {
      "statusCode": 502,
      "errorCode": "BAD_GATEWAY",
      "message": "외부 서버와 통신하는 과정에서 오류가 발생했습니다."
    },
    {
      "statusCode": 503,
      "errorCode": "SERVICE_UNAVAILABLE",
      "message": "외부 서버가 응답할 수 없는 상태입니다."
    },
    {
      "statusCode": 504,
      "errorCode": "GATEWAY_TIMEOUT",
      "message": "외부 서버와 통신하는 과정에서 응답시간이 초과되었습니다."
    }
  ];
  const userError: ErrorCode[] = [
    {
      "statusCode": 401,
      "errorCode": "USER_NOT_MATCH",
      "message": "사용자 정보가 일치 하지 않습니다."
    },
    {
      "statusCode": 404,
      "errorCode": "USER_NOT_FOUND",
      "message": "존재하지 않는 사용자 정보 입니다."
    },
    {
      "statusCode": 404,
      "errorCode": "EMPTY_USER_ID",
      "message": "사용자 ID가 존재하지 않습니다."
    },
    {
      "statusCode": 422,
      "errorCode": "DUPLICATED_EMAIL",
      "message": "이미 존재하는 이메일 계정입니다."
    }
  ];

  const taskError: ErrorCode[] = [
    {
      "statusCode": 400,
      "errorCode": "INVALID_MONTHLY_TASK",
      "message": "올바르지 않은 Task 입니다."
    },
    {
      "statusCode": 400,
      "errorCode": "TOO_MANY_TASKS",
      "message": "등록 가능한 일정 개수를 초과했습니다."
    },
    {
      "statusCode": 404,
      "errorCode": "MONTHLY_TASK_NOT_FOUND",
      "message": "월간 일정표가 존재하지 않습니다."
    },
    {
      "statusCode": 404,
      "errorCode": "TASK_NOT_FOUND",
      "message": "일정을 찾을 수 없습니다."
    },
    {
      "statusCode": 409,
      "errorCode": "MONTHLY_TASK_EXISTS",
      "message": "올 해 월간 일정표가 존재합니다. 새로운 일정표를 생성할 수 없습니다."
    },
    {
      "statusCode": 500,
      "errorCode": "TASK_UN_RESOLVED_EXCEPTION",
      "message": "작업이 실패했습니다. 진행중인 작업을 확인해주세요."
    }
  ];


  return (
    <main className="docs-content">
      <header>
        <h4>API & SDK</h4><br/>
        <h1>API 에러 코드</h1>
        <div>
          Dailyge API를 사용할 때 발생할 수 있는 에러를 살펴보세요.
        </div>
        <br/>
      </header>
      <section className="error-codes">
        <ErrorContainer title="공통 에러" errors={commonError}/>
        <ErrorContainer title="User 에러" errors={userError}/>
        <ErrorContainer title="Task 에러" errors={taskError}/>
      </section>
    </main>
  );
};

export default Docs;