import React, {useEffect, useState} from 'react';
import images from "../home/components/ImageManager";
import LoadingPage from "./LoadingPage";
import axios from 'axios';
import Cookies from "js-cookie";

interface LoginData {
  code: number;
  data: Data;
  message: string;
}

interface Data {
  url: string;
}

interface Props {
  className?: string
}

function Header({className}: Props) {
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  useEffect(() => {
    const loggedIn = Cookies.get("Logged-In");
    setIsLoggedIn(!!loggedIn);
  }, []);

  const handleLoginClick = async () => {
    setLoading(true);

    try {
      const response = await axios.get<LoginData>(process.env.REACT_APP_API_SERVER_URL + "/api/login", {
        withCredentials: true,
      });
      window.location.href = response.data.data.url;
    } catch (error) {
      alert("로그인 요청에 실패하였습니다. 잠시 후 다시 시도해주세요.");
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      Cookies.remove("Logged-In");
      await axios.post(process.env.REACT_APP_API_SERVER_URL + "/api/logout", {}, {
        withCredentials: true, // 쿠키를 포함하여 요청
      });
    } catch (error) {
      console.error('로그아웃 처리 중 오류 발생:', error);
    } finally {
      setIsLoggedIn(false);
      window.location.reload();
    }
  };

  const onClickMyPage = () => {
    window.location.href = process.env.REACT_APP_TASK_CLIENT_URL + "/";
  };

  return (
    <div id="home" className={"container " + (className? className : "") + "header-background"}>
      {loading && <LoadingPage/>}

      <div className={(className? className : "")  + "row"}>
        <header className={(className? className : "")  + "header"}>
          <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-end"
          }}>
            <a href="/">
              <img
                src={images.mainLogoDark}
                alt="Dailyge"
                className="header__logo"
              />
            </a>
            <h2 style={{paddingLeft: "10px"}}></h2>
          </div>
          <a className="toggle-button"></a>

          <nav className="navbar">
            <a href="/">Home</a>
            {isLoggedIn ? (
              <>
                {/*<a href="/notice">Notice</a>*/}
                <a href="/docs/guide">Guide</a>
                <a href="#" onClick={onClickMyPage}>My Task</a>
                <a href="#" onClick={handleLogout}>Logout</a>
              </>
            ) : (
              <>
                {/*<a href="/notice">Notice</a>*/}
                <a href="/docs/guide">Guide</a>
                <a href="#" onClick={handleLoginClick}>Login</a>
              </>
            )}
          </nav>
        </header>
      </div>
    </div>
  );
}

export default Header;
