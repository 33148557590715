import images from "../components/ImageManager";
import React from "react";

function ChooseUs() {
  return (
    <section className="container systematic-background">
      <div className="row">
        <div className="systematic">
          {/* LEFT-SIDE */}
          <div className="systematic-cards">
            <img src={images.systematic} style={{width: "57rem"}} alt="high-quality code"/>
          </div>
          {/* RIGHT-SIDE */}
          <div className="systematic__content">
            <div className="content__text">
              <div className="highlighted-section-name">
                <h2>📑</h2>
                <div>&nbsp; SYSTEMATIC MANAGEMENT</div>
              </div>
              <h2 className="content-title">
                체계적인 일정 관리
              </h2>
              <div className="content-paragraph">
                시간을 체계적으로 관리하면 더 많은 것을 할 수 있습니다.<br/>
                Dailyge는 이러한 사용자의 일정을 보다 더 쉽게 관리할 수 있도록,<br/>
                일정 관리 체계를 직관적으로 설계한 다양한 기능을 제공합니다.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ChooseUs;
