import React from "react";

function Footer() {
  return (
    <footer id="contact" className="container footer-background">
      <div className="row">
        <div className="footer-copyright">
          &copy; Dailyge 2024 All Rights Reserved.
        </div>
      </div>
    </footer>
  );
}

export default Footer;
