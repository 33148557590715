import React from "react";

interface Props {
  title: string;
  errors: ErrorCode[];
}

interface ErrorCode {
  statusCode: number;
  errorCode: string;
  message: string;
}

function ErrorContainer({title, errors}: Props) {

  return (
    <>
      <h2>{title}</h2>
      <table>
        <thead>
        <tr>
          <th>상태 코드</th>
          <th>에러 코드</th>
          <th>메시지</th>
        </tr>
        </thead>
        <tbody>
        {errors.map((error, index) => (
          <tr key={index}>
            <td style={{width: '10%'}}>{error.statusCode}</td>
            <td style={{width: '40%'}}><code>{error.errorCode}</code></td>
            <td style={{width: '50%'}}>{error.message}</td>
          </tr>
        ))}
        </tbody>
      </table>
      <br/><br/>
    </>
  )
}

export default ErrorContainer;
