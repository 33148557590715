import React, {useEffect} from "react";
import images from "../components/ImageManager";

type ServiceData = {
  id: number;
  icon: string;
  title: string;
  desc: string;
};

function Services() {
  const initialData: ServiceData[] = [
    {
      "id": 1,
      "icon": images.serviceIcon1,
      "title": "Simple",
      "desc": "간편한 입력과 깔끔한 인터페이스로 사용자가 손쉽게 일정을 관리할 수 있도록 합니다."
    },
    {
      "id": 2,
      "icon": images.serviceIcon2,
      "title": "Intuitive",
      "desc": "직관적인 디자인으로, 월별이나 주간 일정을 쉽게 시각화하고 한 눈에 정리할 수 있습니다."
    },
    {
      "id": 3,
      "icon": images.serviceIcon3,
      "title": "Management",
      "desc": "강력한 일정 관리 기능을 통해 사용자가 효율적으로 계획하고 추적하여 조정할 수 있습니다."
    },
    {
      "id": 4,
      "icon": images.serviceIcon4,
      "title": "Productivity",
      "desc": "반복되는 일정 등록 작업을 줄이고 생산성을 높일 수 있습니다."
    },
    {
      "id": 5,
      "icon": images.serviceIcon5,
      "title": "Statistics",
      "desc": "일정 관리뿐만 아니라 성과를 분석하고 개선할 수 있는 인사이트를 제공합니다."
    },
    {
      "id": 6,
      "icon": images.serviceIcon6,
      "title": "retrospect",
      "desc": "문제를 스스로 회고하여 비난보다, 반복을 막는 것을 목표로 합니다."
    }
  ];

  useEffect(() => {
    if (initialData.length > 0) {
      renderServices(initialData);
    }
  }, [initialData]);

  const renderServices = (data: ServiceData[]) => {
    const selector = document.querySelector('.services__cards');
    if (!selector) {
      console.error('Selector not found');
      return;
    }

    let html = '';
    for (const oneService of data) {
      html += `
                <div class="card services-card-${oneService.id}">
                    <div class="services-card__images">
                        <img src=${oneService.icon} alt="service" />
                        <div class="serial-number">0${oneService.id}</div>
                    </div>
                    <h4>${oneService.title}</h4>
                    <p>${oneService.desc}</p>
                </div>
            `;
    }

    selector.insertAdjacentHTML('afterbegin', html);
  };

  return (
    <section id="services" className="container services-background">
      <div className="row">
        <div className="services">
          {/* TOP */}
          <div className="services__content">
            <div className="content-name">
              <div className="highlighted-section-name">
                <h2>🔍</h2>
                <div>&nbsp; WHAT DID WE CONSIDER</div>
              </div>
              {/*<p className="section-name">SERVICES</p>*/}
              <h2 className="content-title">
                어떤 점을 고려해서 만들었을까요?
              </h2>
            </div>
            {/*<a href="#" className="btn">Get Started</a>*/}
          </div>
          {/* BOTTOM */}
          <div className="services__cards"></div>
        </div>
      </div>
    </section>
  );
}

export default Services;
