import NoticeList from "./NoticeList";
import Header from "../common/Header";
import React from "react";

function Notice() {
  return (
    <>
      <Header className="docs-"></Header>
      <NoticeList/>
    </>
  );
}

export default Notice;
