import React, {useEffect} from "react";
import Navbar from "./components/Navbar";
import ButtonToTop from "./components/buttonToTop";
import MainImage from './ui/MainImage';
import MoveToTop from "../common/MoveToTop";
import About from "./ui/About";
import Services from './ui/Services';
import ChooseUs from "./ui/ChooseUs";
import Header from "../common/Header";
import Testimonials from "./ui/Testimonials";
import Systematic from "./ui/Systematic";

function Home() {
  useEffect(() => {
    new Navbar();
    // new Sidebar();
    new ButtonToTop();
  }, []);

  return (
    <div className="Main">
      <Header></Header>
      <MainImage></MainImage>
      <ChooseUs></ChooseUs>
      <Systematic></Systematic>
      <About></About>
      {/*<AboutBrand></AboutBrand>*/}
      <Services></Services>
      <Testimonials></Testimonials>
      {/*<LetsTalk></LetsTalk>*/}
      <MoveToTop></MoveToTop>
    </div>
  );
}

export default Home;
