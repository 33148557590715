import {Button} from "react-bootstrap";
import Cookies from "js-cookie";
import axios from "axios";

interface LoginData {
  code: number;
  data: Data;
  message: string;
}

interface Data {
  url: string;
}

function MainImage() {

  const onClickButton = async () => {
    const loggedIn = Cookies.get("Logged-In");
    if (loggedIn) {
      window.location.href = process.env.REACT_APP_TASK_CLIENT_URL + "/";
      return;
    }
    try {
      const response = await axios.get<LoginData>(process.env.REACT_APP_API_SERVER_URL + "/api/login", {
        withCredentials: true,
      });
      window.location.href = response.data.data.url;
    } catch (error) {
      alert("로그인 요청에 실패하였습니다. 잠시 후 다시 시도해주세요.");
    } finally {
    }
  };

  return (
    <div className="animated-lines-wrapper">
      {/*<div className="animated-line animated-line-1"></div>*/}
      {/*<div className="animated-line animated-line-2"></div>*/}
      {/*<div className="animated-line animated-line-3"></div>*/}

      {/* HERO-SECTION --START====================*/}
      <section className="hero-background">
        <div className="row">
          <section className="hero">
            {/* LEFT-SIDE */}
            <div className="hero__content">
              {/*<button className="hero__content-btn">*/}
              {/*    <FontAwesomeIcon icon={faPlay} />*/}
              {/*</button>*/}
              <div className="hero__content-text">
                <h1>일정 관리 <br/>
                  Dailyge로 쉽고 간편하게</h1>
              </div>
              <Button variant="primary" size="lg" onClick={onClickButton}>Get Started</Button>
            </div>

            {/* RIGHT-SIDE */}
            {/*<img src={images.heroImage} alt="rocket" className="hero__image"/>*/}
          </section>
        </div>
      </section>
      {/* HERO-SECTION --END==================== */}
    </div>
  )
}

export default MainImage;
