import images from "../components/ImageManager";
import React from "react";
import 'swiper/css';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay} from "swiper/modules";

function Testimonials() {
  return (
    <section className="container testimonials-background">
      <div className="row">
        <div className="section">
          <div className="testimonials">
            <div className="section__content">
              <div className="highlighted-section-name">
                <h2>🗳️</h2>
                <div>&nbsp; TESTIMONIALS</div>
              </div>
              <h2 className="content-title">실제 사용자들의 후기</h2>
              {/*<p className="content-paragraph">*/}
              {/*  서버를 운영하며 받은 사용자들의 실제 의견*/}
              {/*</p>*/}
            </div>
          </div>
        </div>
        <Swiper
          className='sample-slider h-[180px] sm:h-[200px] lg:h-[270px]'
          modules={[Autoplay]}
          spaceBetween={450} // 사이 거리
          slidesPerView={3} // 화면에 보여질 슬라이드 수
          loop={true} // 무한 루프 활성화
          autoplay={{
            delay: 0,
            pauseOnMouseEnter: false,
            disableOnInteraction: false
          }}
          speed={12000}
          pagination={{clickable: false}}
          allowTouchMove={false}
        >
          <SwiperSlide><img className="testimonials-slide-image" src={images.review1}/></SwiperSlide>
          <SwiperSlide><img className="testimonials-slide-image" src={images.review2}/></SwiperSlide>
          <SwiperSlide><img className="testimonials-slide-image" src={images.review3}/></SwiperSlide>
          <SwiperSlide><img className="testimonials-slide-image" src={images.review4}/></SwiperSlide>
        </Swiper>
        <Swiper
          className='sample-slider h-[180px] sm:h-[200px] lg:h-[270px]'
          modules={[Autoplay]}
          spaceBetween={450} // 사이 거리
          slidesPerView={3} // 화면에 보여질 슬라이드 수
          loop={true} // 무한 루프 활성화
          autoplay={{
            delay: 0,
            pauseOnMouseEnter: false,
            disableOnInteraction: false,
            reverseDirection: true
          }}
          speed={12000}
          pagination={{clickable: false}}
          allowTouchMove={false}
        >
          <SwiperSlide><img className="testimonials-slide-image" src={images.review5}/></SwiperSlide>
          <SwiperSlide><img className="testimonials-slide-image" src={images.review6}/></SwiperSlide>
          <SwiperSlide><img className="testimonials-slide-image" src={images.review7}/></SwiperSlide>
          <SwiperSlide><img className="testimonials-slide-image" src={images.review8}/></SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
}

export default Testimonials;
