import {Button, Pagination, Table} from "react-bootstrap";
import "../../css/home/notice/notice.css"
import {Link} from "react-router-dom";

function NoticeList() {
  let active = 1;
  let items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>,
    );
  }
  return (
    <div className="notice">
      <div className="headers">
        <h1>공지사항</h1><br/>
        <Link to="/notice/register"><Button variant="primary" size="lg">등록하기</Button></Link>
      </div>
      <Table bordered hover>
        <thead>
        <tr>
          <th>번호</th>
          <th>제목</th>
          <th>작성자</th>
          <th>작성일</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>1</td>
          <td>게시글1</td>
          <td>artistJay</td>
          <td>2022-03-19</td>
        </tr>
        <tr>
          <td>2</td>
          <td>게시글2</td>
          <td>artistJay</td>
          <td>2022-03-19</td>
        </tr>
        <tr>
          <td>3</td>
          <td>게시글2</td>
          <td>artistJay</td>
          <td>2022-03-19</td>
        </tr>
        </tbody>
      </Table>
      <div className="center">
        <Pagination size="lg">{items}</Pagination>
      </div>
    </div>
  );
}

export default NoticeList;
