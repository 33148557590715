class ButtonToTop {
  private element: HTMLElement | null;

  constructor() {
    this.element = document.querySelector('.btn-to-top');
    this.showOnScroll();
    this.scrollToTop();
  }

  private showOnScroll() {
    window.addEventListener('scroll', () => {
      if (this.element) {
        if (window.scrollY > 300) {
          this.element.classList.add('btn-to-top-show');
        } else {
          this.element.classList.remove('btn-to-top-show');
        }
      }
    });
  }

  private scrollToTop() {
    if (this.element) {
      this.element.addEventListener('click', () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
      });
    }
  }
}

export default ButtonToTop;
