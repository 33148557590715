class Navbar {
  private headerBG: HTMLElement | null;
  private toggleButton: HTMLElement | null;
  private headerLogo: HTMLImageElement | null;
  private navbar: HTMLElement | null;

  constructor() {
    this.headerBG = document.querySelector('.header-background');
    this.toggleButton = document.querySelector('.toggle-button');
    this.headerLogo = document.querySelector('.header__logo') as HTMLImageElement;
    this.navbar = document.querySelector('.navbar');

    this.showNavbar();
    this.showNavbarOnScroll();
  }

  showNavbar() {
    this.toggleButton?.addEventListener('click', () => {
      this.navbar?.classList.toggle('navbar-show');
    });
  }

  showNavbarOnScroll() {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 10) {
        this.headerBG?.classList.add('header-background-scrolled');
        this.toggleButton?.classList.add('toggle-button-scrolled');
        if (this.headerLogo) this.headerLogo.src = './assets/logos/main-logo-dark.png';
      } else {
        this.headerBG?.classList.remove('header-background-scrolled');
        this.toggleButton?.classList.remove('toggle-button-scrolled');
        if (this.headerLogo) this.headerLogo.src = './assets/logos/main-logo-light.png';
      }
    });
  }
}

export default Navbar;
