import React from 'react';
import {Button} from "react-bootstrap";
import Header from "./Header";

function NotFoundPage() {

  const goHome = () => {
    window.location.href = "/";
  };

  return (
    <>
      <Header></Header>
      <div className="not-found-container">
        <div className="not-found-image"></div>
        <div className="not-found-text-container">
          <div className="not-found-code">404</div>
          <div className="not-found-message">Page Not Found</div>
          <div className="not-found-text">요청하신 페이지를 찾을 수 없습니다.</div>
          <Button className="not-found-button" onClick={goHome}>Go back to Home</Button>
        </div>
      </div>
    </>
  );
};

export default NotFoundPage;
