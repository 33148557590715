import mainLogoLight from '../../../assets/logos/main-logo-light.png';
import mainLogoDark from '../../../assets/logos/main-logo-dark.png';
import heroImage from '../../../assets/images/hero-image.png';
import aboutIcon from '../../../assets/icons/about-icon.png';
import systematic from '../../../assets/images/systematic.png';
import aboutBrandImage from '../../../assets/images/about-brand-image.png';
import chooseUsImage from '../../../assets/images/choose-us-image.jpg';
import chooseUsAnimationTop from '../../../assets/icons/choose-us-animation-top.png';
import chooseUsAnimationDown from '../../../assets/icons/choose-us-animation-down.png';
import chooseUsIcon1 from '../../../assets/icons/choose-us-icon-1.png';
import chooseUsIcon2 from '../../../assets/icons/choose-us-icon-2.png';
import chooseUsIcon3 from '../../../assets/icons/choose-us-icon-3.png';
import talkIcon1 from '../../../assets/icons/talk-icon-1.png';
import talkIcon2 from '../../../assets/icons/talk-icon-2.png';
import talkIcon3 from '../../../assets/icons/talk-icon-3.png';
import testimonialsFriends from '../../../assets/images/testimonials-friends.png';
import serviceIcon1 from '../../../assets/icons/service-icon-1.png';
import serviceIcon2 from '../../../assets/icons/service-icon-2.png';
import serviceIcon3 from '../../../assets/icons/service-icon-3.png';
import serviceIcon4 from '../../../assets/icons/service-icon-4.png';
import serviceIcon5 from '../../../assets/icons/service-icon-5.png';
import serviceIcon6 from '../../../assets/icons/service-icon-6.png';
import testimonialUser1 from '../../../assets/images/testimonial-user1.jpg';
import testimonialUser2 from '../../../assets/images/testimonial-user2.jpg';
import testimonialUser3 from '../../../assets/images/testimonial-user3.jpg';
import testimonialUser4 from '../../../assets/images/testimonial-user4.jpg';
import testimonialMarks from '../../../assets/images/testimonial-marks.png';
import review1 from '../../../assets/images/review1.png';
import review2 from '../../../assets/images/review2.png';
import review3 from '../../../assets/images/review3.png';
import review4 from '../../../assets/images/review4.png';
import review5 from '../../../assets/images/review5.png';
import review6 from '../../../assets/images/review6.png';
import review7 from '../../../assets/images/review7.png';
import review8 from '../../../assets/images/review8.png';
import notFound from '../../../assets/images/not-found.jpg';

const images = {
  mainLogoLight,
  mainLogoDark,
  heroImage,
  aboutIcon,
  systematic,
  aboutBrandImage,
  chooseUsImage,
  chooseUsAnimationTop,
  chooseUsAnimationDown,
  chooseUsIcon1,
  chooseUsIcon2,
  chooseUsIcon3,
  talkIcon1,
  talkIcon2,
  talkIcon3,
  testimonialsFriends,
  serviceIcon1,
  serviceIcon2,
  serviceIcon3,
  serviceIcon4,
  serviceIcon5,
  serviceIcon6,
  testimonialUser1,
  testimonialUser2,
  testimonialUser3,
  testimonialUser4,
  testimonialMarks,
  review1,
  review2,
  review3,
  review4,
  review5,
  review6,
  review7,
  review8,
  notFound
};

export default images;
