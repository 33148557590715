import React from 'react';
import Footer from "./pages/common/Footer";
import Home from "./pages/home/Home";
import {Route, Routes} from 'react-router-dom';
import Docs from "./pages/docs/Docs";
import Guide from "./pages/docs/Guide";
import Api from "./pages/docs/Api";
import Option from "./pages/docs/Option";
import Notice from "./pages/notice/Notice";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./css/home/home.css";
import NoticeRegister from "./pages/notice/NoticeRegister";
import AuthManager from "./pages/auth/AuthManager";
import NotFoundPage from "./pages/common/NotFoundPage";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/api/oauth2" element={<AuthManager />} />
        <Route path="/" element={<Home/>}/>
        <Route path="/notice" element={<Notice/>}/>
        <Route path="/notice/register" element={<NoticeRegister/>}/>
        <Route path="/docs" element={<Docs/>}>
          <Route path="/docs/guide" element={<Guide/>}/>
          {/*<Route path="/docs/api" element={<Api/>}/>*/}
          {/*<Route path="/docs/option" element={<Option/>}/>*/}
        </Route>
        <Route path="/*" element={<NotFoundPage />} />
      </Routes>
      <Footer></Footer>
    </div>
  );
}

export default App;
