import images from "../components/ImageManager";
import React from "react";

function ChooseUs() {
  return (
    <section id="pages" className="container choose-us-background">
      <div className="row">
        <div className="section">
          <div className="choose-us">
            <div className="choose-us__content">
              <div className="highlighted-section-name">
                <h2>🕰️</h2>
                <div>&nbsp; WHY CHOOSE US</div>
              </div>
              <div className="highlighted-section-name"></div>
              <h1 className="content-title">
                일정을 <b style={{color: "#3182F6"}}>효율적</b>으로 <br/>
                관리하는 것이 중요합니다.
              </h1>
              {/*<p className="content-paragraph">*/}
              {/*    열심히 하였음에도 결과가 좋지 못하였을 때, <br/>*/}
              {/*    혹시 스스로 되돌아보신 적 있으신가요?*/}
              {/*</p>*/}
            </div>

            <div className="progress-rows">
              <div className="progress-row">
                <img src={images.chooseUsIcon1} alt="process"/>
                <div className="progress-row__content">
                  <h3>Simple Management</h3>
                  <p>간단한 동작으로 손쉽게 일정을 관리할 수 있습니다.</p>
                </div>
              </div>
              <div className="progress-row">
                <img src={images.chooseUsIcon2} alt="process"/>
                <div className="progress-row__content">
                  <h3>Growing Process</h3>
                  <p>업무에만 집중하실 수 있도록 일정관리와 성장은 대신하여 관리합니다.</p>
                </div>
              </div>
              <div className="progress-row">
                <img src={images.chooseUsIcon3} alt="process"/>
                <div className="progress-row__content">
                  <h3>회고</h3>
                  <p>레포트를 통해 지난 일정들에 대해서 손쉽게 회고할 수 있습니다.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="section__image">
            <img
              src={images.chooseUsImage}
              alt="coworkers"
              className="choose-us__image"
            />
            <img
              src={images.chooseUsAnimationTop}
              alt="animation-1"
              className="choose-us-animation-top"
            />
            <img
              src={images.chooseUsAnimationDown}
              alt="animation-2"
              className="choose-us-animation-down"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default ChooseUs;
