import React from 'react';
import '../../css/home/base/LoadingPage.css';

const LoadingPage = () => {
  return (
    <div className="loading-overlay">
      <div className="loading-text"></div>
    </div>
  );
};

export default LoadingPage;
